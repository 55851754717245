<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md10 lg9 xl8>
        <v-data-table
          dense
          :headers="headers"
          :items="compBancosLista"
          class="elevation-10"
          :search="search"
          :loading="!compBancosProgress"
          loading-text="Carregando..."
          :footer-props="{
            'items-per-page-options': [10, 20, 30],
          }"
          sortBy="id"
          sort-desc
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-cash-multiple</v-icon>
              <v-toolbar-title>Bancos</v-toolbar-title>
              <v-icon class="ml-2" small>mdi-asterisk</v-icon>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="auto" md="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import DatasMixin from "@/mixins/DatasMixin";
// import moment from "moment";

const { mapState, mapActions } = createNamespacedHelpers("tabBancos");

export default {
  // components: { ConfirmDialogue },
  mixins: [DatasMixin],
  name: "TabBancosLista",
  data: () => ({
    listaBancoBancos: "bancos",
    search: "",
    varDialogExcluir: false,
    varExcluirItem: {},
    headers: [
      { text: "ID", value: "id", align: "start" },
      {
        text: "Banco",
        value: "nome",
        sortable: true,
        align: "start",
      },
      {
        text: "Código",
        value: "codigo",
        sortable: true,
        align: "start",
      },
    ],
  }),

  computed: {
    ...mapState(["staErro", "staBancos"]),

    compBancosLista() {
      return this.$store.state.tabBancos.staBancos;
    },
    compBancosProgress() {
      // console.log(!this.$store.state.tabBancos.staBancos.length);
      return this.$store.state.tabBancos.staBancos;
    },
  },

  created() {
    register(this.$store);
    this.actListarBancos();
  },

  watch: {
    erro() {
      if (this.staErro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.staErro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  mounted() {
    //this.obterBancos();
  },

  methods: {
    ...mapActions(["actListarBancos"]),

    metPermissao(field) {
      if (this.perm.some((t) => t.field == field)) {
        const permObject = this.perm.filter((t) => t.field == field);
        if (permObject[0].group[0] == this.$store.state.login.usuario.grupo) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    metVoltar() {
      this.$router.back();
    },
    metRecarregar() {
      this.actListarBancos(this.$store.state.login.licenca);
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },
  },
};
</script>

<style></style>
